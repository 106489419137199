import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import HeroText from "../components/heroText";
import { StaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Index = ({ className }) => {
  return (
    <Layout>
      <SEO title="Οικογενειακή ψυχοθεραπεία" keywords={[`οκογένεια`, `κοινωνικό σύστημα`, `ομάδα`]} />
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "oikogeneiaki.jpg" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={(data) => (
          <div style={{ position: "relative" }}>
            <BackgroundImage
              Tag="section"
              className={className}
              style={{
                height: "100vh",
                backgroundSize: "cover",
                backgroundPosition: "50% 25%",
              }}
              fluid={data.placeholderImage.childImageSharp.fluid}
              backgroundColor={`#040e18`}
            ></BackgroundImage>
            <HeroText
              title="Οικογενειακή θεραπεία"
              styles={{ textAlign: "center" }}
            />
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "rgba(0,0,0,0.3)",
                position: "absolute",
                top: "0px",
              }}
            ></div>
          </div>
        )}
      />

      <div
        className="container main-content"
        style={{ marginTop: "52px", zIndex: "100", position: "relative" }}
      >
        <p>
          H οικογένεια, το κυριότερο ανθρώπινο κοινωνικό σύστημα, είναι μία
          ομάδα ανθρώπων η οποία αποτελείται από τους γονείς και τα παιδιά τους.
          Άλλη ομάδα μπορεί να είναι μια σχολική τάξη, μια ομάδα στο χώρο
          εργασίας, ή ακόμη και μια θεραπευτική ομάδα.
        </p>
        <p>
          Κάθε ομάδα, είναι μοναδική και αποτελεί ένα σύστημα με την δική της
          δομή και τους δικούς της κανόνες. Η σχέση μεταξύ των μελών της ομάδας,
          αλλά και ο τρόπος που σχετίζονται, οι ρόλοι, τα γονεικά πρότυπα, καθώς
          και τα σεξουαλικά πρότυπα, η φροντίδα, η ασφάλεια, η εγγύτητα και
          πολλά άλλα, καθορίζουν σε μεγάλο βαθμό τη προσωπικότητα ενός ανθρώπου.
        </p>
        <p>
          Κάποιες φορές η ισορροπία μεταξύ των μελών της οικογένειας
          διαταράσσεται με αποτέλεσμα η οικογένεια να είναι δυσλειτουργική. Αυτό
          μπορεί να συμβαίνει είτε επειδή η οικογένεια βρίσκεται αντιμέτωπη με
          μια μεταβατική για αυτή περίοδο όπως ένας θάνατος, η απομάκρυνση των
          παιδιών από το σπίτι, ένα διαζύγιο, μια περίοδο αλλαγής δηλαδή, είτε
          πιο συγκεκριμένα γιατί δημιουργούνται σοβαρά προβλήματα ανάμεσα στα
          μέλη όπως συγκρούσεις, έλλειψη επικοινωνίας, σύγχυση στους ρόλους.
          Έτσι, καμιά φορά, προκύπτει η ανάγκη το άτομο να εξεταστεί στο πλαίσιο
          το οποίο ανήκει, δηλαδή την οικογένειά του.
        </p>
        <p>
          Στην οικογενειακή θεραπεία συμμετέχουν όλα τα μέλη της πυρηνικής
          οικογένειας (οι γονείς και τα παιδιά) και αναλύονται τόσο στο παρών
          όσο και στο παρελθόν. Με άλλα λόγια, εξετάζεται η τρέχουσα
          οικογενειακή κατάσταση καθώς και η δυναμική των σχέσεων στην
          οικογένεια προέλευσης των γονιών.
        </p>
        <p>
          Ένας στόχος, λοιπόν, της αναλυτικής οικογενειακής θεραπείας είναι η
          κατανόηση και συνειδητοποίηση των συνειδητών και ασυνείδητων
          δυσλειτουργικών συμπεριφορών των μελών, προκειμένου να μπορούν να
          αναγνωριστούν οι εσωτερικές ανάγκες που πηγάζουν από αυτές τις
          συμπεριφορές. Άλλος στόχος, είναι ο εντοπισμός των ρόλων των μελών της
          οικογένειας, των πεποιθήσεων, καθώς και η κατανόηση του τρόπου που
          σχετίζονται τα μέλη τόσο σε συνειδητό όσο και σε ασυνείδητο επίπεδο. Ο
          θεραπευτής, επιτρέπει την έκφραση όλων των μελών της οικογένειας και
          μέσα από το διάλογο, τη συζήτηση και τις διορθωτικές βιωματικές
          εμπειρίες, προτείνονται τρόποι για αποκατάσταση των ρόλων προκειμένου
          να βελτιωθούν οι σχέσεις μεταξύ των μελών της οικογένειας.
        </p>
        <p>
          Όπως συνηθίζεται και στις άλλες μορφές ψυχοθεραπείας, έτσι και η
          θεραπεία οικογένειας λαμβάνει χώρα σε ένα συγκεκριμένο πλαίσιο, το
          γραφείο του θεραπευτή, αποτελείται από τακτικές συνεδρίες, συνήθως μια
          φορά την εβδομάδα και έχει διάρκεια μια ώρα και τριάντα λεπτά. Στην
          οικογενειακή θεραπεία απαιτείται η παρουσία όλων των μελών της
          οικογένειας σε κάθε συνεδρία.
        </p>
      </div>
    </Layout>
  );
};

export default Index;
